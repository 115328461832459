import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Logo_Xplore_Main_negativ from '../assets/images/Logo_Xplore_Main_negativ.png'

const Header = (props) => (
    <header id="header" className="alt">
        <Link to="/" className="logo"><img src={Logo_Xplore_Main_negativ} alt="" height="80%" style={{paddingTop: '10px'}}/></Link>
        <nav>
            <Link className="menu-link" onClick={props.onToggleMenu} to="/">Menu</Link>
        </nav>
       
    </header>
)

Header.propTypes = {
    onToggleMenu: PropTypes.func
}

export default Header
