import React from 'react'
import { Link } from 'gatsby'

const Contact = (props) => (
    <section id="four">
        <div className="inner">
            <section className="grid-wrapper">
                <div className="col-4">
                    <section>
                        <div className="contact-method">
                            <span className="icon alt fa-envelope"></span>
                            <h3>Email</h3>
                            <Link to="/">support(at)neaxplore.de</Link>
                        </div>
                    </section>
                </div>

                    <div className="contact-method col-4">
                        <span className="icon alt fa-home"></span>
                        <h3>Address</h3>
                        <span>Am Kraftversorgungsturm 5<br /> 52070 Aachen, DE</span>
                    </div>
            </section>
        </div>
    </section>
)

export default Contact
