import React from 'react'
import '../assets/scss/main.scss'
import Header from './Header'
import Menu from './Menu'
import Contact from './Contact'
import Footer from './Footer'
import { CookieBanner } from '@palmabit/react-cookie-law';

class Layout extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isMenuVisible: false,
            loading: 'is-loading'
        }
        this.handleToggleMenu = this.handleToggleMenu.bind(this)
    }

    componentDidMount () {
        this.timeoutId = setTimeout(() => {
            this.setState({loading: ''});
        }, 100);
    }

    componentWillUnmount () {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
    }

    handleToggleMenu() {
        this.setState({
            isMenuVisible: !this.state.isMenuVisible
        })
    }

    render() {
        const { children } = this.props

        return (
            <div className={`body ${this.state.loading} ${this.state.isMenuVisible ? 'is-menu-visible' : ''}`}>
                <div id="wrapper">
                    <Header onToggleMenu={this.handleToggleMenu} />
                    {children}
                    <Contact />
                    <Footer />
                </div>
                <Menu onToggleMenu={this.handleToggleMenu} />
                <CookieBanner
            message="We use cookies to personalise content and ads, to provide social media features and to analyse our traffic. We also share information about your use of our site with our social media, advertising and analytics partners who may combine it with other information that you’ve provided to them or that they’ve collected from your use of their services."
            wholeDomain={true}
            onAccept = {() => {}}
            onAcceptPreferences = {() => {}}
            onAcceptStatistics = {() => {}}
            onAcceptMarketing = {() => {}}
            policyLink='/privacy'
            cookieName="gatsby-gdpr-google-analytics"
            styles={{
                dialog: {
                    position: 'fixed',
                    top: '0',
                    left: '0',
                    right: '0',
                    zIndex: '100000',
                    backgroundColor: '#601e32',
                    padding: '5px',
                  },
                message: {
                    minHeight: '32px',
                    lineHeight: '130%',
                    padding: '10px 0',
                    color: '#ffffff',
                    fontSize: '0.8em',
					letterSpacing: '0',
                    textTransform: 'none',
                    textAlign: 'justify'
                },
                optionLabel: {
                    height: 'auto',
                    width: 'auto',
                    minHeight: '0px',
                    fontSize: '10pt',
                    color: '#c6c6c6',
                    display: 'inline-block',
                    padding: '1px 0 0 30px',
                    position: 'relative',
                    top: '0',
                    left: '0',
                    zIndex: '1',
                    cursor: 'default',
                    verticalAlign: 'top',
                    lineHeight: '1.5'
                  },
                  policy: {
                    fontSize: '10pt',
                    marginLeft: '10px',
                    color: '#ffffff',
                    textDecoration: 'underline',
                    paddingTop :'0px'
                  },
                  button: {
                    display: 'inline-block',
                    backgroundColor: 'rgba(255,255,255,0)',
                    padding: '0px',
                    paddingRight:'10px',
                    paddingLeft:'10px',
                    minWidth: '80px',
                    color: '#ffffff',
                    textDecoration: 'none',
                    fontSize: '10pt',
                    fontWeight: '400',
                    marginRight: '5px',
                    marginLeft: '5px',
                    textAlign: 'center',
                    whiteSpace: 'nowrap',
                    cursor: 'pointer',
                  },
              }}
            />
            </div>
        )
    }
}

export default Layout
